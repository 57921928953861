import Vue from 'vue'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'
import VueAnalytics from 'vue-analytics'
import Meta from 'vue-meta'
import VueI18n from 'vue-i18n'
import Router from 'vue-router'
import Page from './views/Page.vue'
import PageNotFound from './views/PageNotFound.vue'
import store from './store'
import en from './lang/en.json'
import pl from './lang/pl.json'
// import styles
require('./styles/main.sass')

store.dispatch('fetchContent')
.then(() => {
    // use addons
    Vue.config.productionTip = false
    Vue.use(VueScrollTo,{ offset: -80 })
    Vue.use(Meta)
    Vue.use(VueI18n)
    Vue.use(Router)

    const publicPath = '/' + store.getters.getPublicPath
    const locale = store.getters.getLocale
    const defaultLocale = store.getters.getOptions.default_language
    // initialize router
    const router = new Router({
        mode: 'history',
        base: locale != defaultLocale ? publicPath + locale + '/' : publicPath,
        routes: [
            { path: '/'+defaultLocale, redirect: { name: 'home' } },
            { path: '/'+defaultLocale+'/:id', redirect: '/:id' },
            { path: '/'+defaultLocale+'/:parent/:id', redirect: '/:parent/:id' },
            { path: '/', name: 'home', component: Page },
            // { path: '/projects/:id', component: SingleProject},
            { path: '/:id', component: Page },
            { path: '/:parent/:id', component: Page },
            { path: '*', component: PageNotFound },

        ],
        scrollBehavior(to) {
            if (to.hash) {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        VueScrollTo.scrollTo(to.hash, 1000);
                        resolve({ selector: to.hash })
                    }, 100)
                })
            } else {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve({ x: 0, y: 0 })
                    }, 600)
                })
            }
        }
    })
    // config langs
    const i18n = new VueI18n({
        locale: (locale.trim().length && locale != "/") ? locale : defaultLocale,
        fallbackLocale: defaultLocale,
        messages: { en, pl }
    })
    // Google analytics
    if(process.env.VUE_APP_GA){
        Vue.use(VueAnalytics, {
            id: process.env.VUE_APP_GA,
            debug: {
                sendHitTask: process.env.NODE_ENV === 'production'
            },
            router
        })
    }
    // create Vue instance
    const vm = new Vue({
        router,
        i18n,
        store,
        render: h => h(App),
    })
    vm.$mount('#app')
    //  emit "render-event" after fetching data from api endpoint
    document.dispatchEvent(new Event('render-event'))
})
.catch((error) => {
    console.error(error);
});
