<template>
<div v-if="modules">
	<component v-for="(mod,key) in modules"
	           :key="key"
	           :id-number="key+1"
	           :is="getComponentObject(mod.acf_fc_layout)"
	           :data="mod"
	           :class-name="getClassName(mod.acf_fc_layout)"
	           :print-class="printClass"></component>
</div>
</template>

<script>
// mixin for child components
const mixin = {
	props: {
		data: Object,
		printClass: Function,
		className: String,
		idNumber: Number,
	}
}
// Loading child components
import Vue from 'vue'
// require files from subfolder "/flex"
const requireComponent = require.context(
	'./flex', false, /[\w]+\.vue$/
)
// register each component
requireComponent.keys()
	.forEach(fileName => {
		const componentConfig = requireComponent(fileName)
		// remove from filename './' from beginning, '.vue' at the end and ddd flex at beginning
		const componentName = 'Flex' + fileName.substr(0, fileName.length - 4)
			.substr(2);
		// durning load add mixin for each component
		componentConfig.default.mixins = [mixin]
		// register component
		Vue.component(componentName, componentConfig.default || componentConfig)
	})

export default {
	name: 'flexible-content',
	data() {
		return {
			current: this.currentPage
		}
	},
	computed: {
		modules() {
			return this.$store.getters.getPageByPath(this.$route.path)
				.flexible_content
		}
	},
	methods: {
		pascalize(value) {
			let trimmed = value.trim();
			return trimmed.substr(0, 1)
				.toUpperCase() + trimmed.substr(1)
				.replace(/[\W_]$/, '')
				.replace(/[\W_]([a-zA-Z0-9])/g, (_, x) => x.toUpperCase());
		},
		getClassName(name) {
			return 'flex-' + name.replace(/_/g, '-')
		},
		getComponentObject(name) {
			return this.$options.components['Flex' + this.pascalize(name)]
		},
		getOffset(value, after) {
			return (value && after) ? 'is-offset-' + value + '-' + after : ''
		},
		getWidth(value, after) {
			return (value && after) ? 'is-' + value + '-' + after : ''
		},
		getBoolean(value, showIfTrue) {
			return (value && showIfTrue) ? showIfTrue : ''
		},
		printClass(data, field) {
			return [
				this.getWidth(data[field].responsive.fullhd_grid.width, 'fullhd'),
				this.getWidth(data[field].responsive.widescreen_grid.width, 'widescreen'),
				this.getWidth(data[field].responsive.desktop_grid.width, 'desktop'),
				this.getWidth(data[field].responsive.tablet_grid.width, 'tablet'),
				this.getWidth(data[field].responsive.mobile_grid.width, 'mobile'),
				this.getOffset(data[field].responsive.fullhd_grid.offset, 'fullhd'),
				this.getOffset(data[field].responsive.widescreen_grid.offset, 'widescreen'),
				this.getOffset(data[field].responsive.desktop_grid.offset, 'desktop'),
				this.getOffset(data[field].responsive.tablet_grid.offset, 'tablet'),
				this.getOffset(data[field].responsive.mobile_grid.offset, 'mobile'),
				this.getBoolean(data[field].show_line, 'mod-block__content--has-line'),
				this.getBoolean(data[field].big_title, 'mod-block__content--has-big-title')
			]
		}
	}
}
</script>

<style lang="sass">
</style>
