<template>
<div>
	<lazy-image :bamsrc="imageSrc"
	            :bamalt="imageAlt"
	            v-if="imageSrc"
	            class="tile__image"></lazy-image>
</div>
</template>

<script>
import LazyImage from "./../../LazyImage.vue";
export default {
	props: ["tdata"],
	components: {
		"lazy-image": LazyImage
	},
	computed: {
		imageSrc() {
			if (this.tdata.image.from_post && this.tdata.image.post) {
				return this.$store.getters.getPost(this.tdata.image.post)
					.thumbnail;
			} else if (this.tdata.image.image) {
				return this.tdata.image.image.url;
			} else return '';
		},
		imageAlt() {
			if (this.tdata.image.from_post && this.tdata.image.post) {
				return this.$store.getters.getPost(this.tdata.image.post)
					.title;
			} else if (this.tdata.image.image) {
				return this.tdata.image.image.alt;
			} else return '';
		}
	}
}
</script>

<style lang="sass">
.tile__image
	height: 100%
	img
		height: 100%
		width: 100%
</style>
