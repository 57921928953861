<template>
<div>

</div>
</template>

<script>
export default {
	created() {}
}
</script>

<style lang="scss" scoped>
</style>
