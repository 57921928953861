<template>
<section class="section">
	<div class="container">
		<div class="columns is-centered">
			<div class="project-content column is-10">
				<div class="columns"
				     v-if="data.project_title">
					<div class="project-content-title column is-2">
						<p class="project-content-title-text">Project:</p>
					</div>
					<div class="column is-10">
						<p>
							{{data.project_title}}
						</p>
					</div>
				</div>
				<div class="columns"
				     v-if="data.business">
					<div class="project-content-title column is-2">
						<p class="project-content-title-text">Business:</p>
					</div>
					<div class="column is-10">
						<p>
							{{data.business}}
						</p>
					</div>
				</div>
				<div class="columns"
				     v-if="data.work">
					<div class="project-content-title column is-2">
						<p class="project-content-title-text">Work:</p>
					</div>
					<div class="column is-10">
						<p v-for="text in data.work">
							{{text.single}}
						</p>
					</div>
				</div>
			</div>
		</div>
		<router-link :to="'/'+navigation.prev"
		             :class="{'projects-nav__link--disabled':
		             !navigation.prev}"
		             class="projects-nav__link projects-nav__link--prev"></router-link>
		<router-link :to="'/'+navigation.next"
		             :class="{'projects-nav__link--disabled':
		             !navigation.next}"
		             class="projects-nav__link projects-nav__link--next"></router-link>
	</div>
</section>
</template>

<script>
export default {
	data() {
		return {
			navigation: this.$store.getters.getNextAndPrevProject(this.$route.params.id)
		}
	}
}
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"
.section
	.container
		position: relative
		min-height: 250px
		+mobile
			padding-top: 3.2rem
		.projects
			&-nav__link
				position: absolute
				top: 50%
				background-image: url("./../../assets/bm_www_strzalka_poziom.svg")
				background-repeat: no-repeat
				background-size: contain
				width: 50px
				height: 50px
				+mobile
					top: 0
				&--disabled
					display: none
				&--next
					right: 0
					transform: translateY(-50%) rotate(-90deg)
				&--prev
					transform: translateY(-50%) rotate(90deg)
		.project
			&-content
				&-title
					+mobile
						padding-bottom: 0
					&-text
						text-transform: uppercase
						color: $boldFontColor
</style>
