<template>
<picture :class="bamclass"
         v-if="bamsrc"
         class="tiles-image">
	<source :srcset="webpSrc"
	        type="image/webp"
	        v-if="(notGif && webpExists)">
	<source :srcset="bamsrc"
	        type="image/jpeg">
	<img :src="bamsrc"
	     :alt="bamalt">
</picture>
</template>

<script>
import lozad from 'lozad';
export default {
	data() {
		return {
			webpExists: true
		}
	},
	props: {
		bamsrc: {
			type: String,
			required: true
		},
		bamalt: String,
		bamclass: String
	},
	computed: {
		webpSrc() {
			return this.bamsrc + ".webp";
		},
		notGif() {
			if (this.bamsrc.match(/.gif/)) {
				return false;
			} else {
				return true;
			}
		},

	},
	mounted() {
		// const observerImage = lozad(this.$el, {
		// 	loaded: el => el.classList.toggle("lozad-loaded")
		// });
		// observerImage.observe();
	}
}
</script>

<style lang="sass">
    .tiles-image
        width: 100%
        transition: opacity 1s
        opacity: 1
</style>
