<template>
<div class="column">
	<div class="columns is-centered">
		<div class="column is-2 is-offset-1 bam__contact-form">
			<h2 class="title"
			    v-html="data.title">
			</h2>
			<form @submit.prevent="onSubmit">
				<div class="field">
					<label class="label">Name</label>
					<div class="control">
						<input class="input"
						       type="text"
						       name="name"
						       v-model="name"
						       required>
					</div>
				</div>
				<div class="field">
					<label class="label">Email</label>
					<div class="control">
						<input class="input"
						       type="text"
						       name="email"
						       v-model="email"
						       required>
					</div>
				</div>
				<div class="field">
					<label class="label">Message</label>
					<div class="control">
						<textarea class="textarea"
						          name="name"
						          rows="8"
						          cols="80"
						          v-model="message"></textarea>
					</div>
				</div>
				<div class="field">
					<div class="control">
						This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.<br />
						<label class="checkbox">
							<input type="checkbox"
							       name="agreement-1"
							       id="agreement-1"
							       required>
							I agree to the <a href="/terms-and-conditions">terms and conditions</a>
						</label>
					</div>
				</div>
				<vue-recaptcha ref="recaptcha"
				               @verify="onVerify"
				               @expired="onExpired"
				               size="invisible"
				               :sitekey="sitekey">
				</vue-recaptcha>
				<div class="field">
					<div class="control">
						<input class="button is-primary"
						       type="submit"
						       name="submit"
						       :value="sendLabel">
					</div>
				</div>
				<transition name="fade"
				            mode="out-in">
					<article class="message"
					         :class="{'is-success':infoBox.type == 1,'is-danger':infoBox.type == 2}"
					         v-if="infoBox.type != 0"
					         @click="infoBox.type = 0">
						<div class="message-header">
							<p>{{ infoBox.type == 1 ? 'Sucess' : 'Error' }}</p>
							<button class="delete"
							        aria-label="delete"></button>
						</div>
						<div class="message-body">
							{{ infoBox.content }}
						</div>
					</article>
				</transition>
			</form>
		</div>
	</div>
</div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import axios from 'axios'
export default {
	components: {
		'vue-recaptcha': VueRecaptcha
	},
	data() {
		return {
			name: '',
			email: '',
			message: '',
			sendLabel: 'Send',
			infoBox: {
				type: 0, // 0 - no, 1 - success, 2 - error
				content: ''
			},
			sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
		}
	},
	props: {
		data: Object,
		printClass: Function,
		className: String,
	},
	methods: {
		onSubmit() {
			this.$refs.recaptcha.execute()
			this.sendLabel = 'Sending...'
		},
		onVerify(recaptchaToken) {
			this.$refs.recaptcha.reset();
			var bodyFormData = new FormData();
			bodyFormData.set('name', this.name);
			bodyFormData.set('email', this.email);
			bodyFormData.set('message', this.message);
			bodyFormData.set('recaptchaToken', recaptchaToken);

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			axios.post(process.env.VUE_APP_API + 'wp-json/better-api/v1/send', bodyFormData, config)
				.then((response) => {
					if (response.data == 'ok') {
						this.infoBox.type = 1
						this.infoBox.content = 'Email was send successfully.'
						this.sendLabel = 'Send'
						setTimeout(() => {
							this.infoBox.type = 0
						}, 3000)
					} else {
						this.infoBox.type = 2
						this.infoBox.content = 'Server side error'
						this.sendLabel = 'Send'
						setTimeout(() => {
							this.infoBox.type = 0
						}, 3000)
					}

				})
				.catch((err) => {
					this.infoBox.type = 2
					this.infoBox.content = 'Cannot send email, please try again later.'
					this.sendLabel = 'Send'
					setTimeout(() => {
						this.infoBox.type = 0
					}, 3000)
					console.log(getErrorMessage(err));
					//helper to get a displayable message to the user
					function getErrorMessage(err) {
						let responseBody;
						responseBody = err.response;
						if (!responseBody) {
							responseBody = err;
						} else {
							responseBody = err.response.data || responseBody;
						}
						return responseBody.message || JSON.stringify(responseBody);
					}
				})
		},
		onExpired() {
			console.log('Expired')
		}
	}
}
</script>

<style lang="sass">
.grecaptcha-badge
    visibility: hidden
</style>
