<template>
<section class="section">
	<div class="container has-text-centered">
		<div class="columns is-centered">
			<div class="column is-9">
				<h2 v-if="data.title"
				    v-html="data.title"
				    class="title"></h2>
				<p v-html="data.paragraph"></p>
			</div>
		</div>
	</div>
</section>
</template>

<script>
export default {}
</script>

<style lang="sass" scoped>
@import "@/styles/framework/variables.sass"
.column
	text-transform: uppercase
	.title
		font-size: 1rem
		margin-bottom: 1.2rem
		color: #2d2d2d
	    font-weight: 400
	    text-transform: uppercase
	p
		font-weight: 400
		/deep/strong
			color: $boldFontColor
			font-weight: 400
section.section
	padding-bottom: 2rem
</style>
