<template>
<div class="section">
	<div class="container">
		<video :src="data.video"
		       controls
		       v-if="!data.iframe"></video>
		<iframe :src="data.iframe_link"
		        frameborder="0"
		        webkitallowfullscreen
		        mozallowfullscreen
		        allowfullscreen
		        v-if="data.iframe">
		</iframe>
	</div>
</div>
</template>

<script>
export default {}
</script>

<style lang="sass" scoped>
    .container
        display: flex
        justify-content: center
        video, iframe
            height: 400px
            width: 710px
</style>
