<template>
<div id="app">
	<the-header />
	<transition name="fade">
		<router-view :key="$route.params.id" />
	</transition>
	<the-footer />
</div>
</template>

<script>
import TheFooter from '@/components/TheFooter.vue'
import TheHeader from '@/components/TheHeader.vue'
export default {
	components: {
		'the-header': TheHeader,
		'the-footer': TheFooter
	},
	metaInfo() {
		return {
			titleTemplate: this.seoTitleTemplate,
			title: this.page.title,
			link: [{
				rel: 'icon',
				href: this.optionsFavicon ? this.optionsFavicon : ''
			}]
		}
	},
	computed: {
		page() {
			return this.$store.getters.getPageByPath(this.$route.path)
		},
		seoTitleTemplate() {
			return this.$store.getters.getSeoTitleTemplate
		},
		optionsFavicon() {
			return this.$store.getters.getOptionsFavicon
		}
	}
}
</script>
