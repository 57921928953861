<template>
<footer class="footer">
	<div class="container">
		<div class="columns">
			<div class="column is-6">
				<div class="adresses columns is-multiline">
					<div class="column is-6 has-text-centered-mobile"
					     v-for="(adres, index) in adresses">
						<hr :class="{'is-hidden-tablet': index <= 1}"
						    v-if="index > 0">
						<p class="adresses__title"
						   v-if="adres.title">{{adres.title}}</p>
						<div class="adresses__contact"
						     v-if="(adres.mail || adres.number)">
							<p v-if="adres.mail">{{adres.mail}}</p>
							<p v-if="adres.number">{{adres.number}}</p>
						</div>
						<div class="adresses_adres"
						     v-if="adres.adres">
							<p v-html="adres.adres">
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="column is-6">
				<contact-form></contact-form>
			</div>

		</div>
	</div>
</footer>
</template>

<script>
import contactForm from "./ContactForm.vue";
export default {
	data() {
		return {
			adresses: null,
			contactForm: null
		}
	},
	created() {
		this.adresses = this.$store.getters.getFooterAdresses;
		this.contactForm = this.$store.getters.getFooterContactForm;
	},
	components: {
		contactForm
	}
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
.footer {
    background-color: $footerBackgroundColor;
    .adresses {
        text-transform: uppercase;
        font-size: 0.9rem;
        @media screen and (min-width: 769px) and (max-width: 901px) {
            font-size: 0.7rem;
        }
        hr {
            margin-top: 0;
            width: 4rem;
            height: 1px;
            background-color: $footerHrColor;
            @include mobile {
                margin-left: auto;
                margin-right: auto;
            }
        }
        &__title {
            color: $boldFontColor;
            margin-bottom: 1rem;
        }
        &__contact {
            margin-bottom: 1rem;
        }
    }
}
</style>
