<template>
<div>
	<flexible-content />
</div>
</template>

<script>
import FlexibleContent from '@/components/FlexibleContent.vue'
export default {
	components: {
		'flexible-content': FlexibleContent
	}
}
</script>
