<template>
<section class="section"
         :id="data.custom_id">
	<div class="container">
		<p class="bam-tiles__title has-text-centered"
		   v-if="data.title"><strong>{{data.title}}</strong></p>
		<a href="#"
		   v-if="data.custom_id"
		   class="bam-tiles__arrow"
		   v-scroll-to="'#'+data.custom_id"></a>
		<div class="bam-tiles">
			<component :is="tileType(tile)"
			           v-for="(tile,index) in data.single"
			           :class="sizeClass(tile.height,tile.width)"
			           class="bam-tiles__single"
			           :key="index"
			           :tdata="tile"
			           v-if="!hideTile(tile, hidingVariables.windowWidth)">
			</component>
		</div>
	</div>
</section>
</template>

<script>
import TileImage from "./tiles/TileImage.vue";
import TileProject from "./tiles/TileProject.vue";
import TileText from "./tiles/TileText.vue";
import TileVideo from "./tiles/TileVideo.vue";
import TileVideoLink from "./tiles/TileVideoLink.vue";
export default {
	data() {
		return {
			hidingVariables: {
				windowWidth: window.innerWidth,
				timeout: false,
				delay: 250,
				calls: 0
			}
		}
	},
	props: {
		tiles: Array
	},
	components: {
		"tile-image": TileImage,
		"tile-project": TileProject,
		"tile-text": TileText,
		"tile-video": TileVideo,
		"tile-video-link": TileVideoLink
	},
	methods: {
		sizeClass(height, width) {
			return "is-" + height + "-height is-" + width + "-width";
		},
		tileType(tile) {
			switch (tile.content_type) {
				case "image":
					return "tile-image";
				case "text":
					return "tile-text";
				case "project":
					return "tile-project";
				case "video":
					return "tile-video";
				case "video-link":
					return "tile-video-link";
			}
		},
		hideTile(tile, width) {
			if (tile.hide_on_desktop) {
				if (width > 766) {
					return true;
				}
			} else if (tile.hide_on_mobile) {
				if (width <= 766) {
					return true;
				}
			} else return false;
		},
		updateDimension() {
			this.hidingVariables.windowWidth = window.innerWidth;
		}
	},
	mounted() {
		window.addEventListener('resize', () => {
			clearTimeout(this.hidingVariables.timeout);
			this.hidingVariables.timeout = setTimeout(this.updateDimension, this.hidingVariables.delay);
		});
	}
}
</script>

<style lang="scss">
/* TILES */
@import "@/styles/framework/variables.sass";
.bam-tiles__single {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
}
.container {
    .bam-tiles {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: $tileGap;
        grid-auto-flow: dense;
        grid-auto-rows: min-content;
        @include mobile {
            grid-template-columns: 1fr;
        }
        &__title {
            strong {
                color: $boldFontColor;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
        &__arrow {
            width: 30px;
            height: 30px;
            background-image: url("./../../assets/bm_www_strzalka_poziom.svg");
            display: block;
            margin: 0.8rem auto 1rem;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center center;
        }
        &__single {
            overflow: hidden;
            &.is-1-height {
                max-height: $tileHeight;
            }
            &.is-2-height {
                grid-row: span 2;
                max-height: $tile2Height;
            }
            &.is-2-width {
                @include desktop {
                    grid-column: span 2;
                }
            }
            &.is-3-height {
                @include desktop {
                    grid-row: span 3;
                }
            }
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            /deep/div {
                width: 100%;
                height: 100%;
            }
            /deep/img {
                width: 100%;
                height: 100%;
            }
            /deep/picture {
                width: 100%;
                height: 100%;
            }
        }
    }
    @include mobile {
        grid-template-columns: 1fr;
    }
}
</style>
