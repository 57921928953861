<template lang="html">
    <router-link :to="project.link">
        <video  ref="video" muted autoplay playsinline :poster="tdata.video_link.poster.ID ? tdata.video_link.poster.sizes.medium : ''">
            <source :src="tdata.video_link.video" type="video/mp4">
        </video>
    </router-link>
</template>

<script>
export default {
	props: ["tdata"],
	data() {
		return {
			project: this.$store.getters.getProject(this.tdata.video_link.link)
		}
	},
	mounted() {
		let options = {
			root: null,
			threshold: 0.5,
		}
		const observer = new IntersectionObserver((entry) => {
			if (entry[0].isIntersecting) {
				this.$refs.video.play();
			} else {
				this.$refs.video.pause();
			}
		}, options);
		observer.observe(this.$refs.video);
	}
}
</script>

<style lang="scss" scoped>
video {
    height: 100%;
}
</style>
