<template>
<div class="text-container"
     :style="backgroundStyle"
     v-html="tdata.text.text">
</div>
</template>

<script>
export default {
	props: ["tdata"],
	computed: {
		backgroundStyle() {
			if (this.tdata.text.background_type == "color" && this.tdata.text.background_color) {
				return {
					backgroundColor: this.tdata.text.background_color
				};
			} else if (this.tdata.text.background_type == "image" && this.tdata.text.background_image) {
				return {
					backgroundImage: "url(" + this.tdata.text.background_image + ")"
				}
			} else return {}
		}
	}
}
</script>

<style lang="sass" scoped>
.text-container
    display: flex
    justify-content: center
    align-items: center
    width: 100%
</style>
