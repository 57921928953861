<template>
<div>
	<video muted
	       autoplay
	       playsinline
	       loop
	       ref="video"
	       :poster="tdata.video.poster.ID ? tdata.video.poster.sizes.medium : ''">
		<source :src="videoSrc"
		        type="video/mp4">
	</video>
</div>
</template>

<script>
export default {
	props: ["tdata"],
	data() {
		return {
			hidingVariables: {
				windowWidth: window.innerWidth,
				timeout: false,
				delay: 250,
				calls: 0
			},
			videoSrc: this.tdata.video.video_small ? this.tdata.video.video_small : this.tdata.video.video
		}
	},
	methods: {
		videoSource() {
			if (window.innerWidth > 766) {
				this.videoSrc = this.tdata.video.video;
			} else this.videoSrc = this.tdata.video.video_small;
		}
	},
	mounted() {
		this.videoSource();
		let options = {
			root: null,
			threshold: 0.5,
		}
		const observer = new IntersectionObserver((entry) => {
			if (entry[0].isIntersecting) {
				this.$refs.video.play();
			} else {
				this.$refs.video.pause();
			}
		}, options);
		observer.observe(this.$refs.video);
		window.addEventListener('resize', () => {
			clearTimeout(this.hidingVariables.timeout);
			this.hidingVariables.timeout = setTimeout(this.videoSource, this.hidingVariables.delay);
		});
	}
}
</script>

<style lang="scss" scoped>
div {
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
</style>
