<template>
<router-link :to="project.link">
	<lazy-image :bamsrc="projectThumbnail"
	            :bamalt="project.title"
	            class="project__image"></lazy-image>
</router-link>
</template>

<script>
import LazyImage from "./../../LazyImage.vue";
export default {
	props: ["tdata"],
	data() {
		return {
			project: this.$store.getters.getProject(this.tdata.project.project.ID)
		}
	},
	components: {
		"lazy-image": LazyImage
	},
	computed: {
		projectThumbnail() {
			if (this.project.thumbnail) {
				return this.project.thumbnail;
			} else return "";
		}
	}
}
</script>

<style lang="sass">
	.project__image
		height: 100%
		img
			height: 100%
			width: 100%
</style>
