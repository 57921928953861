<template>
<form action=""
      id="contact"
      class="contact-form"
      @submit.prevent="onSubmit">
	<input type="text"
	       class="contact-form__input contact-form__input--small"
	       placeholder="Name"
	       v-model="name"
	       required>
	<input type="email"
	       class="contact-form__input contact-form__input--small"
	       placeholder="Email"
	       v-model="email"
	       required>
	<input type="tel"
	       class="contact-form__input contact-form__input--small"
	       placeholder="Phone"
	       v-model="phone">
	<textarea name="name"
	          rows="8"
	          cols="80"
	          class="contact-form__input"
	          v-model="message"
	          placeholder="MESSAGE"
	          id="contact-form__message"
	          required></textarea>
	<span class="captcha">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</span><br />
	<input type="submit"
	       :value="sendLabel"
	       class="contact-form__submit">
	<vue-recaptcha ref="recaptcha"
	               @verify="onVerify"
	               @expired="onExpired"
	               size="invisible"
	               :sitekey="sitekey">
	</vue-recaptcha>
</form>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import axios from 'axios'
export default {
	components: {
		'vue-recaptcha': VueRecaptcha
	},
	data() {
		return {
			name: "",
			email: "",
			phone: "",
			message: "",
			sendLabel: "Send",
			sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
			infoBox: {
				type: 0, // 0 - no, 1 - success, 2 - error
				content: ''
			}
		}
	},
	methods: {
		onSubmit() {
			this.$refs.recaptcha.execute()
			this.sendLabel = 'Sending...'
		},
		onVerify(recaptchaToken) {
			this.$refs.recaptcha.reset();
			var bodyFormData = new FormData();
			bodyFormData.set('name', this.name);
			bodyFormData.set('email', this.email);
			bodyFormData.set('phone', this.phone)
			bodyFormData.set('message', this.message);
			bodyFormData.set('recaptchaToken', recaptchaToken);

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			axios.post(process.env.VUE_APP_API + 'wp-json/better-api/v1/send', bodyFormData, config)
				.then((response) => {
					if (response.data == 'ok') {
						this.infoBox.type = 1
						this.infoBox.content = 'Email was send successfully.'
						this.sendLabel = 'Send'
						setTimeout(() => {
							this.infoBox.type = 0
						}, 3000)
					} else {
						this.infoBox.type = 2
						this.infoBox.content = 'Server side error'
						this.sendLabel = 'Send'
						setTimeout(() => {
							this.infoBox.type = 0
						}, 3000)
					}

				})
				.catch((err) => {
					this.infoBox.type = 2
					this.infoBox.content = 'Cannot send email, please try again later.'
					this.sendLabel = 'Send'
					setTimeout(() => {
						this.infoBox.type = 0
					}, 3000)
					console.log(getErrorMessage(err));
					//helper to get a displayable message to the user
					function getErrorMessage(err) {
						let responseBody;
						responseBody = err.response;
						if (!responseBody) {
							responseBody = err;
						} else {
							responseBody = err.response.data || responseBody;
						}
						return responseBody.message || JSON.stringify(responseBody);
					}
				})
		},
		onExpired() {
			console.log('Expired')
		}
	}
}
</script>

<style lang="sass" scoped>
    @import "@/styles/framework/variables.sass"
    .contact-form
        &__input
            width: 100%
            background: transparent
            border-top: 0
            border-left: 0
            border-right: 0
            border-bottom: 1px solid $boldFontColor
            outline: none
            padding: 1rem 0
            resize: none
            &:focus
                //background-color: #eee
        &__submit
            background-color: $boldFontColor
            color: white
            text-transform: uppercase
            text-align: center
            border: 0
            float: right
            width: 50%
            margin-top: .5rem
            padding: .3rem
    .contact-form
        &__submit
            background-color: #919191
            font-size: .9rem
        .captcha
            font-size: .7rem
        &__input:not(#contact-form__message)
            text-transform: uppercase
</style>
